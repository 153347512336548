import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as braintree from 'braintree-web-drop-in';
import {BraintreeService} from './braintree.service';

@Component({
    selector: 'app-braintree',
    template: `
        <div id="dropin-container"></div>
        <button id="submit-button" class="btn btn-success m-auto d-none">Procedi con il pagamento</button>
    `,
    styleUrls: ['./braintree.component.scss']
})
export class BraintreeComponent implements OnInit {
    @Input() partner_id;
    @Input() multa_id;
    @Input() scoring;
    @Output() payed: EventEmitter<boolean> = new EventEmitter();

    braintreeToken: string;
    amount: string;

    constructor(
        private braintreeService: BraintreeService
    ) {
    }

    ngOnInit(): void {
        this.braintreeService.getBraintreeToken(this.partner_id, this.multa_id, this.scoring).subscribe(
            res => {
                this.braintreeToken = res.data.token;
                this.amount = res.data.amount;
                this.createBraintreeUI();
            }
        );
    }

    createBraintreeUI() {
        const button = document.getElementById('submit-button');
        const service = this.braintreeService;
        const pid = this.partner_id;
        const mid = this.multa_id;
        const sco = this.scoring;
        const done = this.payed;

        braintree.create({
            authorization: this.braintreeToken,
            container: '#dropin-container',
            locale: 'it_IT',
            threeDSecure: {
                amount: this.amount
            },
            vaultManager: true,
            paypal: {
                flow: 'checkout',
                amount: this.amount,
                currency: 'EUR'
            }
        }, function (createErr, dropinInstance) {
            if (createErr) {
                // Handle any errors that might've occurred when creating Drop-in
                console.error(createErr);
                return;
            }
            button.classList.remove('d-none');
            button.addEventListener('click', function () {
                dropinInstance.requestPaymentMethod(function (err, payload) {
                    if (err) {
                        console.log(err);
                        return;
                    }
                    button.classList.add('d-none');
                    // Send payload.nonce to your server
                    service.sendBraintreePayment(pid, mid, sco, payload.nonce);
                    done.emit(true);
                });
            });
        });
    }

}
