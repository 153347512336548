import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Score} from '../../model/fine';

@Component({
    selector: 'app-scoring',
    template: `
        <label class="label mt-3 ">
            <ng-container *ngIf="score.advice.responso === ''">
                {{ 'scoring.response.label_scoring_1'  | translate }}
            </ng-container>
            <ng-container *ngIf="score.advice.responso !== ''">
                {{ 'scoring.response.label_scoring_2'  | translate }}
            </ng-container>
        </label>
        <ngb-progressbar showValue="true" type="{{score.score < 30 ? 'danger' : (score.score < 70 ? 'warning' : 'success')}}"
                         [value]="score.score" height="35px"></ngb-progressbar>
        <div class="mt-3 mb-5" *ngIf="score.advice.responso !== ''">
            <h6 class="mt-4">{{'scoring.response.final_title' | translate}}</h6>
            <p>{{score.advice.responso}}</p>
            <p>
                <b>{{'scoring.response.ricor_title' | translate}}</b>: {{score.advice.ricorribile === 0 ? 'No' : 'Sì'}}<br>
                <b>{{'scoring.response.ricor_date' | translate}}</b>: {{score.advice.data_scadenza | date: 'd/M/yyyy'}}
            </p>
        </div>

        <div class="checked-values" *ngIf="score.advice.responso === ''">
            <ul class="list-unstyled">
                <li>
                    <i-feather name="x-circle" class="text-danger" *ngIf="score.checks.datawrong"></i-feather>
                    <i-feather name="check-circle" class="text-success" *ngIf="!score.checks.datawrong"></i-feather>
                    Controllo dati anagrafici
                </li>
                <li>
                    <i-feather name="x-circle" class="text-danger" *ngIf="score.checks.dateexpired"></i-feather>
                    <i-feather name="check-circle" class="text-success" *ngIf="!score.checks.dateexpired"></i-feather>
                    Termini di consegna verbale
                </li>
                <li>
                    <i-feather name="x-circle" class="text-danger" *ngIf="score.checks.omologfail"></i-feather>
                    <i-feather name="check-circle" class="text-success" *ngIf="!score.checks.omologfail"></i-feather>
                    Difetti di omologazione e taratura
                </li>
            </ul>
        </div>
        
        <div class="promo-cta">
            <ng-container *ngIf="score.score >= 80">
                <div class="media">
                    <img src="../../../assets/images/scoring-more.png" class="img-fluid" alt="Rocket scoring">
                    <div class="media-body">
                        <h5 class="mt-0">È una percentuale molto valida per fare un ricorso!</h5>
                        <p>Se i dati che hai inserito sono corretti è molto probabile che la tua multa sia ricorribile. Scopri come fare!</p>
                        <button class="btn btn-submit" (click)="goToInfo.emit()">{{ 'scoring.response.box_1_btn'  | translate }}</button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="score.score < 80">
                <div class="media">
                    <img src="../../../assets/images/scoring-less.png" class="img-fluid" alt="Counselor">
                    <div class="media-body">
                        <h5 class="mt-0">La percentuale non è molto alta. Potresti chiedere il parere di un consulente. </h5>
                        <p>Elliot controlla solo i vizi formali ma potrebbero esserci degli aspetti  che rendono la tua multa ricorribile.</p>
                        <button class="btn btn-submit" (click)="goToAdviceInfo.emit()">{{ 'scoring.response.box_2_btn'  | translate }}</button>
                    </div>
                </div>
            </ng-container>
        </div>
        
    `,
    styles: [`
        ngb-progressbar {
            font-weight: bold;
        }
        
        .promo-cta .media {
            margin: 50px auto;
        }

        .promo-cta img {
            width: 115px;
        }
        
        .promo-cta .media-body {
            padding-left: 15px;
        }
        
        .promo-cta h5 {
            color: #DE5F1D;
            font-weight: bold;
            font-size: 15px;
        }
        
        .promo-cta p {
            line-height: 1.3;
            margin-bottom: 8px;
        }
        
        .promo-cta .btn {
            padding-left: 60px;
            padding-right: 60px;
        }
    `]
})
export class ScoringComponent implements OnInit {
    @Input() score: Score;

    @Output() goToInfo: EventEmitter<any> = new EventEmitter();
    @Output() goToAdviceInfo: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

}
