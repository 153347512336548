import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconsModule} from './icons/icons.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ReadablePipe} from './pipes/readable';
import { ScoringComponent } from './components/scoring.component';
import { TableComponent } from './components/table.component';
import {NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import { EditPointsComponent } from './components/edit-points.component';
import { EditPriceComponent } from './components/edit-price.component';
import { TableDetailsComponent } from './components/table-details.component';
import { LoaderComponent } from './components/loader.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { InfoDaysComponent } from './components/info-days.component';
import { ChatAlertComponent } from './components/chat-alert.component';
import { BraintreeComponent } from './components/braintree/braintree.component';
import { CheckEmailComponent } from './components/check-email.component';


@NgModule({
    declarations: [
        ReadablePipe,
        ScoringComponent,
        TableComponent,
        EditPointsComponent,
        EditPriceComponent,
        TableDetailsComponent,
        LoaderComponent,
        InfoDaysComponent,
        ChatAlertComponent,
        BraintreeComponent,
        CheckEmailComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        NgbProgressbarModule,
        IconsModule,
        ReactiveFormsModule,
        MatProgressBarModule
    ],
    exports: [
        IconsModule,
        FormsModule,
        TranslateModule,
        ReadablePipe,
        ScoringComponent,
        TableComponent,
        LoaderComponent,
        BraintreeComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                /* */
            ]
        };
    }
}
