import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {AppState} from '../../core/core.module';
import * as AuthActions from '../../core/auth/store/auth.actions';

@Component({
    selector: 'app-check-email',
    template: `
        <div class="modal-header">
            <h4 class="title-brand">Controlla l'email</h4>
        </div>
        <div class="modal-body text-center">
            <p class="text-lg">
                L'email inserita è corretta?<br>
                <span class="text-brand">{{email}}</span>
            </p>
            <button class="btn btn-success d-inline" (click)="this.confirmEmail()">Sì</button>
            <button class="btn btn-contrast d-inline mt-0 ml-2" (click)="this.activeModal.dismiss()">No</button>
        </div>
    `,
    styles: []
})
export class CheckEmailComponent {
    @Input() email: string;
    @Input() rented: boolean;

    constructor(
        private store: Store<AppState>,
        public activeModal: NgbActiveModal
    ) {
    }

    confirmEmail() {
        if (this.rented) {
            this.store.dispatch(AuthActions.setNewUserRented({email: this.email}));
        } else {
            this.store.dispatch(AuthActions.setNewUser({email: this.email}));
        }
        this.activeModal.dismiss();
    }

}
